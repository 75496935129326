<template>
  <form-wizard
    color="#7367F0"
    :title="null"
    :subtitle="null"
    layout="vertical"
    finish-button-text="Simpan"
    back-button-text="Sebelumnya"
    class="vertical-steps steps-transparent mb-3"
    @on-complete="submitForm"
    @on-change="stepCheck"
  >

    <!-- Data Utama tab -->
    <!-- :before-change="validateDataUtama" -->
    <tab-content
      title="Data Utama"
      :before-change="validateDataUtama"
      :lazy="true"
    >
      <validation-observer ref="formDataUtama">
        <b-form>
          <tambah-data-utama-baru
            :action-step="stepDataUtama"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Aplikasi -->
    <!-- :before-change="validateAplikasi" -->
    <tab-content
      title="Aplikasi"
      :before-change="validateAplikasi"
      :lazy="true"
    >
      <validation-observer ref="formAplikasi">
        <b-form>
          <tambah-aplikasi-single
            :core-data="clearanceData"
            :clearance-id="data_utama_id"
            :action-step="stepAplikasi"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Layanan -->
    <!-- :before-change="validateLayanan" -->
    <tab-content
      title="Layanan"
      :before-change="validateLayanan"
      :lazy="true"
    >
      <validation-observer ref="formLayanan">
        <b-form>
          <tambah-layanan-baru
            :core-data="clearanceData"
            :clearance-id="data_utama_id"
            :action-step="stepLayanan"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Proses Bisnis tab -->
    <!-- :before-change="validateBisnis" -->
    <tab-content
      title="Proses Bisnis"
      :before-change="validateBisnis"
      :lazy="true"
    >
      <validation-observer ref="formBisnis">
        <b-form>
          <tambah-bisnis-baru
            :core-data="clearanceData"
            :sakti="sakti"
            :action-step="stepBisnis"
            :clearance-id="data_utama_id"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Data & Informasi -->
    <!-- :before-change="validateDataInformasi" -->
    <tab-content
      title="Data &amp; Informasi"
      :before-change="validateDataInformasi"
      :lazy="true"
    >
      <validation-observer ref="formInformasi">
        <b-form>
          <tambah-data-informasi-baru
            :core-data="clearanceData"
            :action-step="stepInformasi"
            :clearance-id="data_utama_id"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Infrastruktur -->
    <!-- :before-change="validateInfra" -->
    <tab-content
      title="Infrastruktur"
      :before-change="validateInfra"
      :lazy="true"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Referensi Infrastruktur
          </h5>
          <small class="text-muted">Arsitektur Domain Infrastruktur SPBE yang terkait dengan Aplikasi.</small>
        </b-col>
      </b-row>
      <div
        v-for="(item, index) in totalInfra"
        :id="`infra-${item.id}`"
        :key="item.id"
        ref="row"
        :title="`Data Referensi Infrastruktur ${item.id}`"
      >
        <div class="border-bottom mb-0 card-text p-0 d-flex">
          <b-button
            v-b-toggle
            :href="`#collapse-infra-${item.id}`"
            variant="flat-secondary"
            class="text-left flex-grow-1"
            @click.prevent
          >
            Data Referensi Infrastruktur {{ item.id }}
          </b-button>
          <b-button
            variant="flat-danger"
            @click="removeInfra(index)"
          >
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </div>
        <b-collapse
          :id="`collapse-infra-${item.id}`"
          class="mb-1"
          visible
        >
          <tambah-infrastruktur-referensi
            :key="`inf_${item.id}`"
            :app-data="item"
            :core-data="clearanceData"
            :clearance-id="data_utama_id"
            :action-step="stepInfra"
            :reference-data="infRefData"
            @step-completed="stepCompleted"
          />
        </b-collapse>
      </div>

      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-button
            variant="success"
            class="mt-0 mt-md-2"
            @click="repeateInfra"
          >
            <span>Tambah Referensi Infrastruktur</span>
          </b-button>
        </b-col>
      </b-row>
    </tab-content>

    <!-- Keamanan -->
    <!-- :before-change="validateKeamanan" -->
    <tab-content
      title="Keamanan"
      :before-change="validateKeamanan"
      :lazy="true"
    >
      <validation-observer ref="formKeamanan">
        <b-form>
          <tambah-keamanan
            :clearance-id="data_utama_id"
            :action-step="stepKeamanan"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <div v-if="errorMsg">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          <ul v-if="typeof errorMsg === 'object'">
            <li
              v-for="(item, index) in errorMsg"
              :key="index"
            >
              {{ index }}:
              <span
                v-for="(child, indexs) in item"
                :key="indexs"
              >
                {{ child }}
              </span>
            </li>
          </ul>
          <span v-else>{{ errorMsg }}</span>
        </div>
      </b-alert>
    </div>

  </form-wizard>
</template>

<script>
import store from '@/store'
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BForm, BCollapse, BAlert, BButton, BRow, BCol, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import TambahDataUtamaBaru from '../tambah-kegiatan/TambahDataUtamaBaru.vue'
import TambahLayananBaru from '../tambah-kegiatan/TambahLayananBaru.vue'
import TambahBisnisBaru from '../tambah-kegiatan/TambahBisnisBaru.vue'
import TambahDataInformasiBaru from '../tambah-kegiatan/TambahDataInformasiBaru.vue'
import TambahAplikasiSingle from '../tambah-kegiatan/TambahAplikasiSingle.vue'
import TambahInfrastrukturReferensi from '../tambah-kegiatan/TambahInfrastrukturReferensi.vue'
import TambahKeamanan from '../tambah-kegiatan/TambahKeamanan.vue'

export default {
  components: {
    ValidationObserver,
    FormWizard,
    TabContent,
    BAlert,
    BForm,
    BRow,
    BCol,
    BButton,
    BCollapse,

    TambahDataUtamaBaru,
    TambahAplikasiSingle,
    TambahLayananBaru,
    TambahBisnisBaru,
    TambahDataInformasiBaru,
    TambahInfrastrukturReferensi,
    TambahKeamanan,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: getUserData(),
      stepChange: 0,
      stepDataUtama: 0,
      stepBisnis: 0,
      stepLayanan: 0,
      stepInformasi: 0,
      stepAplikasi: 0,
      stepInfra: 0,
      stepKeamanan: 0,
      letter_id: '',
      jenis_pengadaan_id: 0,
      data_utama_id: 0,
      clearanceData: {},
      errorMsg: '',
      form: {},
      message: '',
      totalAplikasi: [],
      jumlahAplikasi: 0,
      totalInfra: [],
      jumlahInfra: 0,
      sakti: {},
      infRefData: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/pengajuan/surat')
    } else {
      next()
    }
  },
  created() {
    localStorage.removeItem('dataBaru')
    this.letter_id = this.$route.params.id
    this.jenis_pengadaan_id = this.$route.params.jenis
  },
  mounted() {
    this.$http.get('/clearance', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.letter_id,
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.clearanceData = res.data.data
        }
      })
  },
  methods: {
    getInfReference() {
      this.$http.get('/clearance/infrastruktur/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.infRefData = res.data.data
          }
        })
    },
    repeateInfra() {
      const jumlah = this.jumlahInfra + 1
      this.totalInfra.push({
        id: jumlah,
      })
      this.jumlahInfra = jumlah
    },
    removeInfra(data) {
      this.totalInfra.splice(data, 1)
    },
    validateDataUtama() {
      this.$refs.formDataUtama.validate()
        .then(this.stepDataUtama += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
            localStorage.setItem('dataBaru', value.msg.data_utama_id)
            this.getInfReference()
          }
          resolve(value.status)
        })
      })
    },
    validateBisnis() {
      this.$refs.formBisnis.validate()
        .then(this.stepBisnis += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateLayanan() {
      this.$refs.formLayanan.validate()
        .then(this.stepLayanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateDataInformasi() {
      this.$refs.formInformasi.validate()
        .then(this.stepInformasi += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateAplikasi() {
      this.$refs.formAplikasi.validate()
        .then(this.stepAplikasi += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateInfra() {
      if (this.jumlahInfra !== 0) {
        return new Promise(resolve => {
          this.stepInfra += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
              this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      this.errorMsg = 'Referensi Infrastruktur Tidak Boleh Kosong'
      return false
    },
    validateKeamanan() {
      this.$refs.formKeamanan.validate()
        .then(this.stepKeamanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    submitForm() {
      localStorage.removeItem('dataBaru')
      this.$router.replace({ name: 'kegiatan-detail', params: { id: this.data_utama_id } })
    },
    stepCheck(e) {
      this.stepChange = e
      this.errorMsg = ''
    },
    stepCompleted(data) {
      this.sakti = store.state.app.sakti
      this.$emit('step-validated', data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
