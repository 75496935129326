<template>
  <section id="tambah-permohonan">
    <!--
      2. Aplikasi
      3. Infrastruktur
      6. Data
      7. Non Tekni
    -->
    <tambah-infrastruktur v-if="jenis_pengadaan_id == '2'" />
    <tambah-aplikasi v-if="jenis_pengadaan_id == '3'" />
    <tambah-data v-if="jenis_pengadaan_id == '6'" />
    <tambah-non v-if="jenis_pengadaan_id == '7'" />
  </section>
</template>

<script>
import TambahInfrastruktur from './tambah/TambahKegiatanInfrastruktur.vue'
import TambahAplikasi from './tambah/TambahKegiatanAplikasi.vue'
import TambahData from './tambah/TambahKegiatanData.vue'
import TambahNon from './tambah/TambahKegiatanNon.vue'

export default {
  components: {
    TambahInfrastruktur,
    TambahAplikasi,
    TambahData,
    TambahNon,
  },
  data() {
    return {
      jenis_pengadaan_id: '',
    }
  },
  created() {
    this.jenis_pengadaan_id = this.$route.params.jenis
  },
}
</script>
