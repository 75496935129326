<template>
  <form-wizard
    color="#7367F0"
    :title="null"
    :subtitle="null"
    layout="vertical"
    finish-button-text="Simpan"
    back-button-text="Sebelumnya"
    class="vertical-steps steps-transparent mb-3"
    @on-complete="submitForm"
    @on-change="stepCheck"
  >

    <!-- Data Utama tab -->
    <!-- :before-change="validateDataUtama" -->
    <tab-content
      title="Data Utama"
      :before-change="validateDataUtama"
      :lazy="true"
    >
      <validation-observer ref="formDataUtama">
        <b-form>
          <tambah-data-utama-baru
            :action-step="stepDataUtama"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Dokumen -->
    <!-- :before-change="validateItemBelanja" -->
    <tab-content
      title="Item Belanja"
      :before-change="validateItemBelanja"
      :lazy="true"
    >
      <validation-observer ref="formItemBelanja">
        <b-form>
          <tambah-dokumen
            :core-data="clearanceData"
            :clearance-id="data_utama_id"
            :action-step="stepItemBelanja"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Proses Bisnis tab -->
    <!-- :before-change="validateBisnis" -->
    <tab-content
      title="Proses Bisnis"
      :before-change="validateBisnis"
      :lazy="true"
    >
      <validation-observer ref="formBisnis">
        <b-form>
          <tambah-bisnis-baru
            :core-data="clearanceData"
            :clearance-id="data_utama_id"
            :action-step="stepBisnis"
            :sakti="sakti"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Layanan -->
    <!-- :before-change="validateLayanan" -->
    <tab-content
      title="Layanan"
      :before-change="validateLayanan"
      :lazy="true"
    >
      <validation-observer ref="formLayanan">
        <b-form>
          <tambah-layanan-baru
            :core-data="clearanceData"
            :clearance-id="data_utama_id"
            :action-step="stepLayanan"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <div v-if="errorMsg">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          <ul v-if="typeof errorMsg === 'object'">
            <li
              v-for="(item, index) in errorMsg"
              :key="index"
            >
              {{ index }}:
              <span
                v-for="(child, indexs) in item"
                :key="indexs"
              >
                {{ child }}
              </span>
            </li>
          </ul>
          <span v-else>{{ errorMsg }}</span>
        </div>
      </b-alert>
    </div>

  </form-wizard>
</template>

<script>
import store from '@/store'
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BForm, BAlert, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import TambahDataUtamaBaru from '../tambah-kegiatan/TambahDataUtamaBaru.vue'
import TambahDokumen from '../tambah-kegiatan/TambahDokumen.vue'
import TambahBisnisBaru from '../tambah-kegiatan/TambahBisnisBaru.vue'
import TambahLayananBaru from '../tambah-kegiatan/TambahLayananBaru.vue'

export default {
  components: {
    ValidationObserver,
    FormWizard,
    TabContent,
    BAlert,
    BForm,

    TambahDataUtamaBaru,
    TambahDokumen,
    TambahBisnisBaru,
    TambahLayananBaru,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: getUserData(),
      stepChange: 0,
      stepDataUtama: 0,
      stepBisnis: 0,
      stepLayanan: 0,
      stepItemBelanja: 0,
      letter_id: '',
      jenis_pengadaan_id: 0,
      data_utama_id: 0,
      clearanceData: {},
      errorMsg: '',
      form: {},
      message: '',
      sakti: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/pengajuan/surat')
    } else {
      next()
    }
  },
  created() {
    localStorage.removeItem('dataBaru')
    this.letter_id = this.$route.params.id
    this.jenis_pengadaan_id = this.$route.params.jenis
  },
  mounted() {
    this.$http.get('/clearance', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.letter_id,
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.clearanceData = res.data.data
        }
      })
  },
  methods: {
    validateDataUtama() {
      this.$refs.formDataUtama.validate()
        .then(this.stepDataUtama += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
            localStorage.setItem('dataBaru', value.msg.data_utama_id)
          }
          resolve(value.status)
        })
      })
    },
    validateBisnis() {
      this.$refs.formBisnis.validate()
        .then(this.stepBisnis += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateLayanan() {
      this.$refs.formLayanan.validate()
        .then(this.stepLayanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateItemBelanja() {
      this.$refs.formItemBelanja.validate()
        .then(this.stepItemBelanja += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    submitForm() {
      localStorage.removeItem('dataBaru')
      this.$router.replace({ name: 'kegiatan-detail', params: { id: this.data_utama_id } })
    },
    stepCheck(e) {
      this.stepChange = e
      this.errorMsg = ''
    },
    stepCompleted(data) {
      this.sakti = store.state.app.sakti
      this.$emit('step-validated', data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
